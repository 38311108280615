<template>
  <div class="live-map-tools">
    <o-map-zoom-tool-group />
  </div>
</template>

<script>
import OMapZoomToolGroup from '@components/Map/MapZoomToolGroup.vue'

export default {
  name: 'MapTools',

  components: {
    OMapZoomToolGroup,
  },
}
</script>

<style lang="scss">
@import 'variables';

.live-map-tools {
  position: absolute;
  top: $spacing-s;
  right: $spacing-s;
  z-index: 999;

  // Fix disappearing tools when zoom in/out
  transform: translate3d(0px, 0px, 0px);
  will-change: transform;
}
</style>
