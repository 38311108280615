<template>
  <div style="display: none">
    <div
      ref="marker"
      class="o-map-square-marker__content"
      :class="'o-map-square-marker__content--' + this.color"
    >
      <o-icon class="o-map-square-marker__icon" :name="iconName" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'OMapSquareMarker',

  props: {
    iconName: {
      type: String,
      default: 'users',
    },

    color: {
      type: String,
      default: 'blue',
    },

    label: {
      type: String,
      default: '',
    },

    latLng: {
      type: Array,
      default: null,
    },

    opacity: {
      type: Number,
      default: 1,
    },
  },

  inject: ['onMap'],

  computed: {
    classNames() {
      return [`o-map-square-marker__wrapper--${this.color}`]
    },
  },

  mounted() {
    const { Marker, DivIcon } = require('leaflet')

    this.$divIcon = new DivIcon({
      iconSize: null,
      iconAnchor: [9, 9],
      className: 'o-map-square-marker',
      // Leaflet use `appendChild` to add HTML and you can't append
      // the component root element for some Vuejs internal reasons.
      // This hack keeps the reactive props operation.
      html: this.$refs['marker'],
    })

    this.$marker = new Marker(this.latLng, {
      icon: this.$divIcon,
      opacity: this.opacity,
    })
    this.$marker.on('click', (e) => this.$emit('click', e))

    this.$marker.bindTooltip(this.label, {
      direction: 'top',
      offset: [0, -8],
    })

    this.onMap.then((map) => {
      map.addLayer(this.$marker)
    })
  },

  watch: {
    label(newLabel) {
      this.$marker.setTooltipContent(newLabel)
    },

    opacity(newOpacity) {
      this.$marker.setStyle({
        opacity: newOpacity,
      })
    },
  },

  beforeDestroy() {
    this.onMap.then((map) => {
      map.removeLayer(this.$marker)
    })
  },
}
</script>

<style lang="scss">
@import 'variables';

.o-map-square-marker {
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 18px;
    height: 18px;
    line-height: 1;
    font-weight: $font-weight-bold;
    border-radius: $border-radius-s;

    @each $name, $color in $color-markers {
      &--#{$name} {
        color: $color;
        background-color: rgba(lighten($color, 40%), 0.7);
      }
    }
  }

  &__icon {
    font-size: 10px;
  }

  &:hover {
    z-index: 2000 !important;
  }
}
</style>
