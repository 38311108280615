<template>
  <b-message
    class="block"
    :active="errorMessages.length > 0"
    :title="title"
    type="is-danger"
    has-icon
    icon-size="is-medium"
    :closable="closable"
  >
    <ul>
      <li v-for="error of errorMessages" :key="error">{{ error }}</li>
    </ul>
  </b-message>
</template>

<script>
export default {
  name: 'OMessage',

  props: {
    errorMessages: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
