<template>
  <div
    class="o-map-tool-item"
    :class="{ 'o-map-tool-item--active': active }"
    @click.stop="$emit('click', $event)"
  >
    <slot>
      <o-icon :name="iconName" />
    </slot>
  </div>
</template>

<script>
export default {
  name: 'OMapToolItem',

  props: {
    iconName: String,

    active: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
@import 'variables';

$o-map-tool-item-color: $color-secondary-light;
$o-map-tool-item-background-hover: $color-secondary-darkest;
$o-map-tool-item-color-hover: $color-secondary-lightest;

.o-map-tool-item {
  display: flex;
  padding: $spacing-s;
  line-height: 1;
  color: $o-map-tool-item-color;

  &:hover {
    cursor: pointer;
  }

  &--active,
  &:hover {
    background-color: $o-map-tool-item-background-hover;
    color: $o-map-tool-item-color-hover;
    border-radius: $border-radius-s;
  }
}
</style>
