<template>
  <o-map-tool-group>
    <o-map-tool-item
      v-for="item of items"
      :key="item.iconName"
      :iconName="item.iconName"
      @click="item.action"
    />
  </o-map-tool-group>
</template>

<script>
import OMapToolItem from './MapToolItem.vue'
import OMapToolGroup from './MapToolGroup.vue'

export default {
  name: 'OMapZoomToolGroup',

  components: {
    OMapToolItem,
    OMapToolGroup,
  },

  inject: ['onMap'],

  data() {
    return {
      items: [
        { iconName: 'plus', action: () => this.changeMapZoom('zoomIn') },
        { iconName: 'minus', action: () => this.changeMapZoom('zoomOut') },
      ],
    }
  },

  methods: {
    changeMapZoom(action) {
      this.onMap.then((map) => {
        map[action]()
      })
    },
  },
}
</script>
