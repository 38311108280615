const types = {
  csv: {
    mime: 'text/csv',
    extension: '.csv',
  },
  gpx: {
    mime: 'application/gpx+xml',
    extension: '.gpx',
  },
  pdf: {
    mime: 'application/pdf',
    extension: '.pdf',
  },
}

export const downloadFile = (content, type, filename) => {
  const file = new Blob([content], {
    type: types[type].mime,
  })

  const downloadLink = document.createElement('a')
  downloadLink.download = filename + types[type].extension
  downloadLink.href = window.URL.createObjectURL(file)
  downloadLink.style.display = 'none'

  document.body.appendChild(downloadLink)
  downloadLink.click()
}
