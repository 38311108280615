export const importCsv = (csv) => {
  const lines = csv.split(/\r?\n/).filter((line) => line.length > 0)

  let result = []

  const headers = lines[0].split(',')

  for (let i = 1; i < lines.length; i++) {
    let obj = {}
    const currentLine = lines[i].split(',')

    for (let j = 0; j < headers.length; j++) {
      if (!currentLine[j]) {
        obj[headers[j]] = ''
      } else {
        obj[headers[j]] = currentLine[j]
      }
    }

    result.push(obj)
  }

  return result
}
