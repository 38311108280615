export const exportToCSV = (data) => {
  if (data.length === 0) {
    return
  }

  const columnNames = Object.keys(data[0])
  let csv = [columnNames.join(',')]

  data.forEach((obj) => {
    const rows = columnNames.map((key) => {
      if (!obj[key]) {
        return ''
      }
      return obj[key]
    })

    csv.push(rows.join(','))
  })

  return csv.join('\n')
}
