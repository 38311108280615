<template>
  <b-field :class="className" :type="{ 'is-danger': errorMessage }" :message="errorMessage">
    <b-upload :value="value" class="file-label" :disabled="loading" @input="$emit('input', $event)">
      <span class="file-cta">
        <b-icon v-show="!value" class="file-icon" icon="file-import" size="is-small" />
        <span class="file-label">{{ text }}</span>
      </span>
    </b-upload>
    <b-field v-show="value" class="field has-addons">
      <p class="control" />
      <p class="control">
        <b-button
          :disabled="loading"
          class="button"
          type="is-outlined"
          @click="$emit('deleteFile')"
        >
          <b-icon
            class="file-icon"
            icon="trash"
            size="is-small"
            :type="loading ? 'is-dark' : 'is-danger'"
          />
        </b-button>
      </p>
      <p class="control">
        <b-button
          :loading="loading"
          :disabled="loading || errorMessage"
          class="button"
          type="is-outlined"
          @click="$emit('submit')"
        >
          <b-icon
            class="file-icon"
            icon="file-import"
            size="is-small"
            :type="loading ? 'is-light' : 'is-primary'"
          />
        </b-button>
      </p>
    </b-field>
  </b-field>
</template>

<script>
import { checkFileType } from '@helpers/checkFileType'

export default {
  name: 'OUpload',

  props: {
    label: {
      type: String,
      default: 'Déposez votre fichier',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      validator: (v) => v === null || v instanceof File,
    },
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      errorMessage: null,
    }
  },

  watch: {
    value(value) {
      value ? (this.errorMessage = checkFileType(value, this.type)) : (this.errorMessage = null)
    },
  },

  computed: {
    text() {
      return this.value ? this.value.name : this.label
    },

    className() {
      return this.value ? 'has-name is-outlined' : 'is-light'
    },
  },
}
</script>
