<template>
  <div class="o-map">
    <slot />
  </div>
</template>

<script>
import { panes } from '@components/Map/configs'

export default {
  name: 'OMap',

  props: {
    tileLayer: {
      type: Object,
      required: true,
      validator: (v) => ['url', 'options'].every((key) => key in v),
    },

    zoom: {
      type: Number,
      default: 8,
    },

    center: {
      type: Array,
      default: () => [0, 0],
    },

    minZoom: {
      type: Number,
      default: 0,
    },

    bounds: {
      type: Array,
      default: null,
    },
  },

  provide() {
    return {
      onMap: new Promise((resolve) => (this.$ready = resolve)),
    }
  },

  mounted() {
    const { Map, Control } = require('leaflet')

    this.$map = new Map(this.$el, {
      center: this.center,
      zoom: this.zoom,
      minZoom: this.minZoom,
      attributionControl: false,
      zoomControl: false,
      doubleClickZoom: false,
      tap: false,
    })

    this.$map.on('click', (e) => this.$emit('click', e))

    // Zoom on map when doubleclick only on map component
    this.$map.on('dblclick', (e) => {
      const { target } = e.originalEvent

      if (this.$el === target) {
        e.target.zoomIn()
      }
    })

    this.$tileLayer = this.createTileLayer(this.tileLayer)
    this.$map.addLayer(this.$tileLayer)

    // TODO: Remove custom map for Gran Trail Aneto 2022
    if (this.$route.params.slug === 'gttap-2022') {
      this.$tileLayerTrailAneto = this.createTileLayer({
        url: 'https://trail-aneto.com/tiles/{z}/{x}/{y}.png',
        options: {
          opacity: 0.6,
          maxZoom: 17,
          crossOrigin: false,
        },
      })

      this.$map.on('zoomend', () => {
        var zoom = this.$map.getZoom()
        if (zoom < 14 && !this.$map.hasLayer(this.$tileLayer)) {
          this.$map.addLayer(this.$tileLayer)
          this.$map.removeLayer(this.$tileLayerTrailAneto)
        }
        if (zoom >= 14 && this.$map.hasLayer(this.$tileLayer)) {
          this.$map.removeLayer(this.$tileLayer)
          this.$map.addLayer(this.$tileLayerTrailAneto)
        }
      })
    }

    this.$scaleControl = new Control.Scale({
      position: 'bottomright',
      imperial: false,
    })
    this.$scaleControl.addTo(this.$map)

    panes.forEach((pane) => this.createCustomMapPane(this.$map, pane))

    this.$ready(this.$map)
  },

  watch: {
    tileLayer(newTileLayer, oldTileLayer) {
      if (oldTileLayer.url !== newTileLayer.url) {
        this.$map.removeLayer(this.$tileLayer)

        this.$tileLayer = this.createTileLayer(newTileLayer)
        this.$map.addLayer(this.$tileLayer)
      }
    },

    center(newCenter) {
      if (newCenter === null) {
        return
      }

      this.$map.panTo(newCenter)
    },

    zoom(newZoom) {
      if (newZoom === null) {
        return
      }

      this.$map.setZoom(newZoom)
    },
  },

  methods: {
    fitBounds(points) {
      const { LatLngBounds } = require('leaflet')
      const bounds = new LatLngBounds(points)

      if (!bounds.isValid()) {
        return
      }

      this.$map.fitBounds(bounds)
    },

    resize() {
      this.$map.invalidateSize()
    },

    createTileLayer(tileLayer) {
      const { TileLayer } = require('leaflet')

      return new TileLayer(tileLayer.url, {
        crossOrigin: true,
        ...tileLayer.options,
      })
    },

    createCustomMapPane(map, { name, zIndex }) {
      map.createPane(name)
      map.getPane(name).style.zIndex = zIndex
    },
  },
}
</script>

<style src="leaflet/dist/leaflet.css"></style>

<style lang="scss">
@import 'variables';

$o-map-tooltip-directions: 'left' 'right' 'top' 'bottom';

$o-map-tooltip-background: rgba($color-secondary-dark, 0.9);
$o-map-tooltip-color: $color-secondary-lightest;

.leaflet-container {
  font: inherit;
}

.leaflet-tooltip {
  padding: $spacing-s;
  border: none;
  background-color: $o-map-tooltip-background;
  color: $o-map-tooltip-color;
  line-height: 1;
  box-shadow: none;
}

@each $direction in $o-map-tooltip-directions {
  .leaflet-tooltip-#{$direction}:before {
    border-#{$direction}-color: $o-map-tooltip-background;
    #{$direction}: 100%;
  }
}

.leaflet-control {
  // Fix disappearing control when zoom in/out
  transform: translate3d(0px, 0px, 0px);
  will-change: transform;
}
</style>
