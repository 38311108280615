<template>
  <o-map
    ref="map"
    class="map-view"
    :center="mapCenter"
    :bounds="mapBounds"
    :tileLayer="mapLayer"
    @click="$emit('click', $event)"
  >
    <slot />
    <map-tools />
  </o-map>
</template>

<script>
import OMap from '@components/Map/Map.vue'
import MapTools from './MapTools/index.vue'

export default {
  name: 'MapView',

  components: {
    MapTools,
    OMap,
  },

  props: {
    mapCenter: {
      type: Array,
      default: () => [0, 0],
    },
    mapBounds: {
      type: Array,
      default: () => [0, 0],
    },
  },

  data() {
    return {
      currentMapZoom: 10,
    }
  },

  created() {
    this.mapLayer = {
      name: 'osm',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      options: {
        tileSize: 256,
        zoomOffset: 0,
      },
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.map?.fitBounds(this.mapBounds)
      this.$refs.map?.$map.on('zoomend', this.onZoomEnd)
    })
  },

  watch: {
    mapBounds(bounds) {
      this.$refs.map?.fitBounds(bounds)
    },
  },

  methods: {
    resize() {
      this.$nextTick(() => {
        this.$refs.map.resize()
      })
    },

    onZoomEnd(e) {
      this.currentMapZoom = e.target.getZoom()
    },
  },

  beforeDestroy() {
    this.$refs.map.$map.off('zoomend', this.onZoomEnd)
  },
}
</script>

<style lang="scss">
.map-view {
  position: relative;
  z-index: 0;
}
</style>
