<template>
  <div class="o-map-tool-group" @mousedown.stop="" @mousemove.stop="" @pointerdown.stop="">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'OMapToolGroup',
}
</script>

<style lang="scss">
@import 'variables';

$o-map-tool-group-background-color: $color-secondary-dark;

.o-map-tool-group {
  width: max-content;
  margin-bottom: $spacing-s;
  border-radius: $border-radius-s;
  background-color: $o-map-tool-group-background-color;
}
</style>
